import { useState } from "react";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";

const ImageUpload = ({ setFileDataURL, setFormError, loadimage = "" }) => {
  const [image, setImage] = useState(loadimage);
  const [currentPage, setCurrentPage] = useState(loadimage ? 'load' : "choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState(loadimage);

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
    setFormError(null);
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = 300 //imgCroppedArea.width;
    canvasEle.height = 300 //imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        canvasEle.width,
        canvasEle.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      setFileDataURL(dataURL);
      setCurrentPage("img-cropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
  };

  return (
    <div className="cropperview">
      {currentPage === "choose-img" ? (
        <FileInput setImage={setImage} onImageSelected={onImageSelected} />
      ) : currentPage === "crop-img" ? (
        <ImageCropper image={image} onCropDone={onCropDone} onCropCancel={onCropCancel} />
      ) : (
        <div>
          <div>
            <img src={imgAfterCrop} alt="personalisiertes Bild" className="cropped-img" />
          </div>
          <button onClick={() => { setCurrentPage("choose-img"); setImage(""); setFileDataURL(null); }}>Neues Bild wählen</button>
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
