import { useEffect, useState } from 'react';
import ImageUpload from './personalizationpage/ImageUpload';
import PageNotFound from './PageNotFound';
import ApiService from "../services/api.service";

const PersonalizationUpdate = () => {

    const [fileDataURL, setFileDataURL] = useState(null);

    const queryParameters = new URLSearchParams(window.location.search);
    const ticketnumber = queryParameters.get('ticket-number');

    const [error, setError] = useState(null);
    const [formError, setFormError] = useState(null);

    const [checkResponse, setCheckResponse] = useState();

    if (error && error !== '404') {
        throw error;
    }

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthday, setBirthday] = useState('');
    const [postal, setPostal] = useState('');
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [marketingContactPermitted, setMarketingContactPermitted] = useState(false);

    const setPersonalization = (e) => {

        let errorOnForm = false;

        if (firstname === '' || lastname === '' || birthday === '') {
            setFormError('Bitte fülle alle Felder aus.');
            errorOnForm = true;
            return false;
        }

        if (fileDataURL === null) {
            setFormError('Bitte lade ein Bild hoch.')
            errorOnForm = true;
            return false;
        }

        // eslint-disable-next-line
        let regex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        if (email === '') {
            setFormError('Deine E-Mail-Adresse fehlt.');
            errorOnForm = true;
            return false;
        }
        else if (!email.match(regex)) {
            setFormError('Deine E-Mail-Adresse stimmt nicht.');
            errorOnForm = true;
            return false;
        }


        if (privacyPolicyAccepted === false) {
            setFormError('Bitte akzeptiere die Datenschutzbestimmungen.')
            errorOnForm = true;
            return false;
        }

        if (!errorOnForm) {
            const API_URL = global.config.express.url + '/setpersonalizationupdate';
            const API_DATA = {
                'shop-url': global.config.shopurl,
                'id': checkResponse.id,
                'ticketnumber': ticketnumber,
                'image': fileDataURL,
                'email': email,
                'firstname': firstname,
                'lastname': lastname,
                'birthday': birthday,
                'postal': postal,
                'privacyPolicyAccepted': privacyPolicyAccepted,
                'marketingContactPermitted': marketingContactPermitted,
            }

            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    let url;
                    if (response === 'success') {
                        url = window.location.pathname + '?path=personalisieren-abgeschlossen'
                        window.location.replace(url);
                    }
                    else {
                        url = window.location.pathname + '?path=personalisieren-fehlgeschlagen'
                        window.location.replace(url);
                    }
                }
            }).catch((error) => {
                setError('NO_CONNECTION')
            });
        }
    }


    useEffect(() => {
        if (!ticketnumber) {
            setError('404')
            return false
        }
        const API_URL = global.config.express.url + '/getpersonalization';
        const API_DATA = {
            'shop-url': global.config.shopurl,
            'ticketnumber': ticketnumber
        }

        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror)
            }
            else {
                setFileDataURL(response.image ? response.image : '');
                setEmail(response.email ? response.email : '');
                setFirstname(response.firstname ? response.firstname : '');
                setLastname(response.lastname ? response.lastname : '');
                setBirthday(response.birthday ? response.birthday : '');
                setPostal(response['postal-code'] ? response['postal-code'] : '');
                setPrivacyPolicyAccepted(response.privacyPolicyAccepted);
                setMarketingContactPermitted(response.marketingContactPermitted);
                setCheckResponse(response)
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });

    }, [ticketnumber])


    let view;

    if (checkResponse) {
        if (typeof checkResponse === 'object') {
            view =
                <div className='container t-center'>
                    <h1>Daten aktualisieren</h1>
                    <p>Die Änderungen sind dauerhaft und können später nicht mehr geändert werden!</p>
                    <ImageUpload setFileDataURL={setFileDataURL} setFormError={setFormError} loadimage={checkResponse.image} />
                    <label>E-Mail-Adresse*</label>
                    <div className='forminputholder'>
                        <input type='text' defaultValue={checkResponse.email} onChange={(e) => { setFormError(null); setEmail(e.target.value); }} />
                    </div>
                    <label>Vorname*</label>
                    <div className='forminputholder'>
                        <input type='text' defaultValue={checkResponse.firstname} onChange={(e) => { setFormError(null); setFirstname(e.target.value); }} />
                    </div>
                    <label>Nachname*</label>
                    <div className='forminputholder'>
                        <input type='text' defaultValue={checkResponse.lastname} onChange={(e) => { setFormError(null); setLastname(e.target.value); }} />
                    </div>
                    <label>Geburtsdatum*</label>
                    <div className='forminputholder'>
                        <input type='date' defaultValue={checkResponse.birthday} onChange={(e) => { setFormError(null); setBirthday(e.target.value); }} />
                    </div>
                    <label>Postleitzahl</label>
                    <div className='forminputholder'>
                        <input type='text' maxLength={10} defaultValue={checkResponse['postal-code']} onChange={(e) => { setFormError(null); setPostal(e.target.value); }} />
                    </div>
                    <div className='forminputholder checkbox'>
                        <input className='checkbox' type='checkbox' checked={checkResponse.privacyPolicyAccepted} onChange={(e) => { setFormError(null); setPrivacyPolicyAccepted(privacyPolicyAccepted === false ? true : false); }} />
                        <label className='checkbox'>Ich akzeptiere die <a href="https://karls.de/datenschutz/" target="_blank" rel="noreferrer">Datenschutzbestimmungen</a>.</label>
                    </div>
                    <div className='forminputholder checkbox'>
                        <input className='checkbox' type='checkbox' checked={checkResponse.marketingContactPermitted} onChange={(e) => { setFormError(null); setMarketingContactPermitted(marketingContactPermitted === false ? true : false); }} />
                        <label className='checkbox'>Ich akzeptiere die Nutzung für Marketingzwecke.</label>
                    </div>
                    <div className='submitbox'>
                        {formError ? <span className='error'>{formError}</span> : ''}
                        <button className='submit full' onClick={(e) => setPersonalization(e)}>Speichern</button>
                    </div>
                </div>
        }
        else if (checkResponse === 'notfound') {
            view =
                <div className='container t-center'>
                    <h1>Daten aktualisieren</h1>
                    <p>Dieses Ticket existiert nicht!</p>
                </div>
        }
        else if (checkResponse === 'notpersonalized') {
            view =
                <div className='container t-center'>
                    <h1>Daten aktualisieren</h1>
                    <p>Dieses Ticket wurde noch nicht personalisiert!</p>
                </div>
        }
        else {
            view =
                <div className='container t-center'>
                    <h1>Daten aktualisieren</h1>
                    <p>Dieses Ticket kann nicht geändert werden!</p>
                </div>
        }
    }



    let content =
        error === '404'
            ? <PageNotFound />
            : view

    return content


}

export default PersonalizationUpdate;
