import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ApiService from '../services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import * as GlobalStore from '../store/global/actions';


import ticketbut from '../images/ticket-button.png';
import ios from '../images/appstore.png';
import google from '../images/playstore.png';
import qrcode from '../images/qr-app.png';

const StartPage = () => {

    useLocation();

    const dispatch = useDispatch();

    const queryParameters = new URLSearchParams(window.location.search);
    const appid = queryParameters.get('karlsplus-app-id');
    const appticketnumber = queryParameters.get('ticket-number');
    const [response, setResponse] = useState();
    const [error, setError] = useState(null);

    const [windowState, setWindowState] = useState();

    const prePageState = useSelector(state => state.global.prepagehidden)
    const [prePageHidden, setPrePageHidden] = useState(prePageState);

    let prePage;

    const setPrePageState = (state) => {
        setPrePageHidden(state);
        dispatch(GlobalStore.setPrePageHidden(state));
    }

    if (appid) {
        dispatch(GlobalStore.setAppId(appid));
        if (prePageState === false) {
            setPrePageState(true);
        }
    }
    else {

        let contsection;
        let mobile = false;

        if (windowState === 'desktop') {
            mobile = false;
            contsection =
                <>
                    <img className='qr' src={qrcode} alt="App" />
                    <p className='flex small'>Kostenlos scannen & herunterladen</p>
                </>
        }
        else {
            mobile = true;
            contsection =
                <>
                    <p className='flex m'>
                        <span className="buts">
                            <Link className="darkbutton" to='https://apps.apple.com/de/app/karls/id1498904466'><img src={ios} alt="iOS App Store" /></Link>
                            <Link className="darkbutton" to='https://play.google.com/store/apps/details?id=de.karls.karls'><img src={google} alt="Google Play Store" /></Link>
                        </span>
                    </p>
                    <p className='flex small m'>Jetzt kostenlos downloaden</p>
                </>
        }


        prePage =
            <div className='col-xs-12 prepage'>
                <h1>Spare Zeit & Geld</h1>
                <p className={mobile ? 'm lined' : 'lined'}>Tickets in der Karls App mit<br /> bis zu <span className="green">25% Rabatt</span> kaufen!</p>
                <h2><span className='num'>1.</span> Karls App herunterladen</h2>
                {contsection}
                <div className='flex inapp'>
                    <h2 className={mobile ? 'm dt' : 'dt'}><span className='num'>2.</span> Tickets in der App kaufen</h2>
                    <p><img className='ticketbut' src={ticketbut} alt="App" />Button klicken & Tickets kaufen. Digital nutzen oder vor Ort ausdrucken lassen.</p>
                </div>
                <p className={mobile ? 'm last' : 'last'}>Tickets teurer ohne App kaufen:</p>
                <button type='button' onClick={() => setPrePageState(true)}>Hier klicken</button>
            </div>
    }

    if (appticketnumber) {
        dispatch(GlobalStore.setAppTicketNumber(appticketnumber));
    }
    else {
        dispatch(GlobalStore.setAppTicketNumber(null));
    }

    if (error) throw error;

    let categoryBoxes;
    let textAfter;

    if (response && response.categories) {
        let navbuttons = [];
        response.categories.forEach(e => {
            let imgpath = global.config.apiurl + '/' + e.image['relative-path'];
            navbuttons.push(
                <Link className='navbox' key={e.id} to={'?path=' + e.identifier}><img src={imgpath} alt={e.name} /></Link>
            )
        });
        textAfter = <div className='col-xs-12 text bottom'>Tickets werden für kostenpflichtige Attraktionen benötigt. Tageskarten sind nicht datumsgebunden und können jederzeit genutzt werden.<br /><br />Für das Karls Erlebnis-Dorf Warnsdorf bieten wir keine Tageskarten an: Tickets für die Traktorbahn können direkt vor Ort erworben werden.</div>
        categoryBoxes = <div className='col-xs-12'>{navbuttons}</div>
    }

    useEffect(() => {
        dispatch(GlobalStore.resetProductQtys());
        dispatch(GlobalStore.resetPayments());
        if (!response) {
            if (global.config.shopurl) {
                const API_URL = global.config.express.url + '/categories';
                const API_DATA = {
                    'shop-url': global.config.shopurl,
                    'app': global.config.inapp
                }
                ApiService.getData(API_URL, API_DATA).then((response) => {
                    if (typeof (response.apierror) !== 'undefined') {
                        setError(response.apierror);
                    }
                    else {
                        setResponse(response);
                    }
                }).catch((error) => { setError('NO_CONNECTION') });
            }
            else {
                setError('PARAMS_MISSING');
            }
        }
        const observer = new ResizeObserver(() => {
            if (window.innerWidth > 767) {
                setWindowState('desktop');
            }
            else {
                setWindowState('mobile');
            }
        });
        observer.observe(document.body);
    }, [response, dispatch]);

    return (
        <>
            <div className='container t-center'>
                <div className='row'>
                    {!prePageHidden && prePage}
                    {prePageHidden ? categoryBoxes : ''}
                    {prePageHidden ? textAfter : ''}
                    {prePageHidden && !appid ? <button className="link backtoapppage" type='button' onClick={() => setPrePageState(false)}>zurück zur App-Seite</button> : ''}
                </div>
            </div>
        </>
    );
}

export default StartPage;
