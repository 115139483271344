import './css/main.scss';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import StartPage from './components/StartPage';
import ProductPage from './components/ProductPage';
import CheckoutPage from './components/CheckoutPage';
import OverviewPage from './components/OverviewPage';
import OrderSuccessPage from './components/OrderSuccessPage';
import OrderFailedPage from './components/OrderFailedPage';
import PageNotFound from './components/PageNotFound';
import Header from './components/mainelements/Header';
import PersonalizationPage from './components/PersonalizationPage';
import PersonalizationSuccessPage from './components/PersonalizationSuccessPage';
import PersonalizationFailedPage from './components/PersonalizationFailedPage';
import PersonalizationUpdate from './components/PersonalizationUpdate';


const App = () => {

    useLocation();

    const body = document.querySelector('body');
    var [previousUrl, setPreviousUrl] = useState(document.location.href);

    function getComponentByParam() {
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get('path');
        document.getElementById('karlsticketshop').scrollTo(0, 0);
        if (path === null || path === 'start') {
            return < StartPage />
        }
        else if (path.includes('/')) {
            return < ProductPage />
        }
        else if (path === 'checkout') {
            return < CheckoutPage />
        }
        else if (path === 'bestelluebersicht') {
            return < OverviewPage />
        }
        else if (path === 'bestellung-abgeschlossen') {
            return < OrderSuccessPage />
        }
        else if (path === 'bestellung-fehlgeschlagen') {
            return < OrderFailedPage />
        }
        else if (path === 'personalisieren') {
            return < PersonalizationPage />
        }
        else if (path === 'personalisieren-abgeschlossen') {
            return < PersonalizationSuccessPage />
        }
        else if (path === 'personalisieren-fehlgeschlagen') {
            return < PersonalizationFailedPage />
        }
        else if (path === 'personalisieren-update') {
            return < PersonalizationUpdate />
        }
        else {
            return < PageNotFound />
        }
    }

    function getClassBySize(size) {

        if (size > 1679) {
            return 'xxl'
        }
        else if (size > 1399 && size < 1680) {
            return 'xl'
        }
        else if (size > 1199 && size < 1400) {
            return 'lg'
        }
        else if (size > 991 && size < 1200) {
            return 'md'
        }
        else if (size > 767 && size < 992) {
            return 'sm'
        }
        else {
            return 'xs'
        }
    }

    const ref = useRef(null);

    useEffect(() => {
        const element = ref?.current;
        if (!element) return;

        const observer = new ResizeObserver(() => {
            element.setAttribute('class', 'k_wrapper');
            let sizeClass = getClassBySize(element.offsetWidth);
            element.classList.add(sizeClass);
        });
        observer.observe(element);



        const _paq = window._paq;
        const pageobserver = new MutationObserver(() => {
            if (typeof _paq !== 'undefined') {
                if (document.location.href !== previousUrl) {
                    _paq.push(['setReferrerUrl', previousUrl]);
                    _paq.push(['setCustomUrl', document.location.href]);
                    _paq.push(['trackPageView']);
                    setPreviousUrl(document.location.href);
                }
            }
        });

        pageobserver.observe(body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            pageobserver.disconnect();
        };
    }, [ref, body, previousUrl])

    return (
        <div className='k_wrapper' ref={ref}>
            <ErrorBoundary>
                <Header />
                {getComponentByParam()}
            </ErrorBoundary>
        </div>
    );
}

export default App;
